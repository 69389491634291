import React, { useEffect, useState, Suspense } from "react";

import * as XLSX from "xlsx";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Pagination from "@mui/material/Pagination";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RefreshIcon from "@mui/icons-material/Refresh";
import { IconButton, InputAdornment, MenuItem, Stack, TextField, Tooltip } from "@mui/material";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

import { CustomAlert } from "../../../Utils";
import API_endpoint from "../../../utility/APIEndPoints";
import ApiRequest from "../../../utility/APIRequest";
import FixedTags from "../../../components/MultiSelectTest/MultiSelectTest";
import WarningMessage from "../../../components/WarningMessage/WarningMessage";
import { Footer, CustomizedTables, Header, PageLoader, AlertBanner } from "../../../components";
import DashboardReportBox from "../../../components/DashboardReportBox/DashboardReportBox";
import { chapterList, filterFields, FieldsNameUsingKey, URL } from "../../../Constant/StaticData";
import { formatDownlableData, GetPagination, handleSerchDataFormat, ValidateTradeAnalysisForm, ValidateTradeAnalysisFormNew, subscriptionModal } from "../../../Utils/commonFunction";
import NotificationBadge from "../../../components/NotificationBadge/NotificationBadge";

const USATradeAnalysis = () => {
  let initialFormData = { chapter: 21, dataType: "import", selectedFieldType: "product_name", selectedField: "" };
  const [isLoading, setIsLoading] = useState({ table: false, download: false, nextLevelFilter: false });
  const [formData, setFormData] = useState(initialFormData);
  const [page, setPage] = useState("1");
  const [filteredData, setFilteredData] = useState();
  const [shortAnalysis, setShortAnalysis] = useState({ loader: false, data: [] });
  const [selectedFilterField, setSelectedFieldData] = useState({ tValue: "", list: [] });
  const [selectedFieldType, setSelectedFieldType] = useState("product_name");
  const [formValidation, setFormValidation] = useState("");
  const [uniqueList, setUniqueList] = useState();
  const [secondLayerRefresh, setSecondLayerRefresh] = useState(false);
  const baseUrl = window.location.origin;

  const onSubmit = (e) => {
    e.preventDefault();
    getFilterData(formData, false, true); // Paramater 1: search data, 2:is data to download 3: is first search
  };

  const handleDownloadFilterData = () => {
    if (shortAnalysis?.data?.Shipments < 20001) {
      if (filteredData?.subscription) {
        getFilterData(formData, true, true);
      } else {
        CustomAlert("Subscription not found", "You have subscription to download the data. Please connect our sales team!", "warning", 3000);
      }
    } else {
      CustomAlert("Download Limit", "You have limit of 20,000 data to download at a time. Please try with less data or connect support!", "warning", 3000);
    }
  };

  const getFilterData = async (formData, filterForDownload = false, isFirstTime) => {
    localStorage.setItem("usa_filter", JSON.stringify(formData));
    let isValidation = ValidateTradeAnalysisForm(formData);
    setFormValidation(isValidation?.errorList);

    if (isValidation?.status) {
      setIsLoading({ table: !filterForDownload, download: filterForDownload });
      let APIData = await handleSerchDataFormat(formData, filterForDownload || isFirstTime ? "1" : page, filterForDownload);
      let ApiEndPoint = formData?.dataType === "import" ? API_endpoint?.USA?.import : API_endpoint?.USA?.export;

      ApiRequest.request(ApiEndPoint, "POST", APIData).then((response) => {
        if (response?.status) {
          if (filterForDownload) {
            downloadExcel(response?.result?.searchResult);
            setIsLoading({ table: false, download: false });
          } else {
            setFilteredData(response?.result);
            if (isFirstTime && response?.result?.searchResult?.length > 0) {
              getUniqueDataList(APIData, formData?.dataType);
              getShortAnalysis(APIData, formData?.dataType);
            }
            // if (response?.result?.subscription) {}
            setIsLoading({ table: false, download: false });
          }
        } else {
          CustomAlert("Invalid Action", "something went wrong!", "error", 5000);
          setIsLoading({ table: false, download: false });
        }
      });
    }
  };

  const getUniqueDataList = (apiData, type) => {
    // setUniqueList(() => setUniqueList());
    ApiRequest.request(`/analytics/usa/${type}/unique-analysis`, "POST", apiData).then((response) => {
      if (response?.status) {
        setUniqueList(response?.result);
        console.log("Unique API Data", response?.result);
      }
    });
  };

  // Handling short analysis
  const getShortAnalysis = (apiData, type) => {
    setShortAnalysis({ loader: true, data: [] });

    ApiRequest.request(`/analytics/usa/${type}/sort-analysis`, "POST", apiData).then((response) => {
      if (response?.status) {
        setShortAnalysis({ loader: false, data: response?.result });
      }
    });
  };

  const addSelectedFieldData = async () => {
    let dataValidation = await ValidateTradeAnalysisFormNew(selectedFieldType, selectedFilterField?.tValue, formData?.chapter);
    setFormValidation(dataValidation?.errorList);

    if (dataValidation?.status) {
      if (selectedFilterField?.list?.length == 5) {
        setFormValidation({ selectedField: { status: true, message: `Max 5 filter is allowed` } });
      } else if (selectedFilterField?.list?.includes(selectedFilterField?.tValue)) {
        setFormValidation({ selectedField: { status: true, message: `Same data multiple times is allowed` } });
      } else {
        let data = [...selectedFilterField?.list];
        data.push(selectedFilterField?.tValue);
        setSelectedFieldData((selectedFilterField) => ({ ...selectedFilterField, tValue: "", list: data }));
        handleFormdata({ value: data, name: "selectedField" });
      }
    }
  };

  const removeSelectedFieldData = (value) => {
    let oldData = [...selectedFilterField?.list];
    let newData = oldData?.filter((item) => item !== value);
    setSelectedFieldData((selectedFilterField) => ({ ...selectedFilterField, list: newData }));
    handleFormdata({ value: newData, name: "selectedField" });
  };

  const handleFormdata = (data) => {
    const { name, value } = data;
    setFormData({ ...formData, [name]: value });

    console.log("Snd lyr", formData);
  };

  const handleFieldTypeSelection = (data) => {
    setSelectedFieldData({ tValue: "", list: [] });
    setSelectedFieldType(data.value);
    setFormData({ ...formData, selectedFieldType: data.value, selectedField: [] });
  };

  const refreshSecondLayer = () => {
    setFormData({ ...formData, s_importer_name: [], s_exporter_name: [], s_country: [], country_of_destination: [], port_of_loading: [], port_of_discharge: [] });
    setSecondLayerRefresh(!secondLayerRefresh);
  };

  // download the excel of data
  const downloadExcel = (sourceData) => {
    let data = formatDownlableData(sourceData);
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "DataSheet.xlsx");
    setIsLoading({ table: false, download: false });
    CustomAlert("Thankyou for downloading!", "Your Data is downloaded sucessfully.", "success", 3000);
  };

  const handleCountryWiseAnalysis = async () => {
    let data = await handleSerchDataFormat(formData, "1", true);
    window.open(`${baseUrl}/#/shipment-wise-analysis?country=usa`, "_blank");
  };

  useEffect(() => {
    filteredData && getFilterData(formData, false, false); // Next page data will show on page change
  }, [page]);

  return (
    <div className="import_export">
      <PageLoader open={isLoading?.table || isLoading?.download} message="We are processing the data. Please wait..." />
      <Header />
      <NotificationBadge />

      <Container maxWidth="xl" className="import_export_filter">
        <form>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {/* <Grid item xs={4}> */}
              <FormControl variant="standard" sx={{ m: 1, minWidth: "100px" }}>
                <TextField id="outlined-select-currency" name="chapter" size="small" select label="Chapter" defaultValue="21" onChange={(e) => handleFormdata(e.target)} className="f_level">
                  {chapterList?.map((item) => {
                    return (
                      <MenuItem key={item?.value} value={item?.value}>
                        {item?.name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </FormControl>

              <FormControl variant="standard" sx={{ m: 1 }}>
                <TextField id="outlined-select-currency" name="dataType" size="small" select label="Type" defaultValue="import" onChange={(e) => handleFormdata(e.target)} className="f_level">
                  <MenuItem value="import">Import Data</MenuItem>
                  <MenuItem disabled value="export">
                    Export Data
                  </MenuItem>
                </TextField>
              </FormControl>

              <FormControl variant="standard" sx={{ m: 1, minWidth: "200px" }}>
                <TextField
                  id="outlined-select-currency"
                  size="small"
                  select
                  label="Select Field"
                  defaultValue="product_name"
                  style={{ height: "none!important", fontSize: "12px!important" }}
                  onChange={(e) => handleFieldTypeSelection(e.target)}
                  className="f_level"
                >
                  {filterFields?.map((item) => {
                    return (
                      <MenuItem key={item?.value} value={item?.value}>
                        {item?.name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </FormControl>
              {/* </Grid>
            <Grid item xs={3}> */}
              <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined" className="f_level">
                <InputLabel htmlFor="outlined-adornment-password">{FieldsNameUsingKey[selectedFieldType]}</InputLabel>
                <OutlinedInput
                  id="selectFieldType"
                  type="text"
                  name="selectFieldType"
                  label={FieldsNameUsingKey[selectedFieldType]}
                  value={selectedFilterField?.tValue}
                  onChange={(e) => setSelectedFieldData({ ...selectedFilterField, tValue: e.target.value })}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        // onClick={(e) => onTypeSelectedFilterField(e.target.value)}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        <AddCircleIcon color="primary" onClick={() => addSelectedFieldData()} />
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {formValidation?.selectedField?.status && <WarningMessage type="error" text={formValidation?.selectedField?.message} />}

                <Stack spacing={2}>
                  {selectedFilterField?.list?.map((item) => {
                    return (
                      <Tooltip key={item} title={item}>
                        <Chip key={item} label={item} onDelete={() => removeSelectedFieldData(item)} style={{ width: "100%", justifyContent: "space-between", marginTop: "5px" }} />
                      </Tooltip>
                    );
                  })}
                </Stack>
              </FormControl>
              {/* </Grid>
            <Grid item xs={3}> */}
              <FormControl sx={{ m: 1, minWidth: "10%" }} variant="standard">
                <TextField
                  // label="Supplier"
                  variant="outlined"
                  size="small"
                  id="toDate"
                  type="date"
                  name="start_date"
                  onChange={(e) => handleFormdata(e.target)}
                />
                {formValidation?.start_date?.status && <WarningMessage type="error" text={formValidation?.start_date?.message} />}
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: "10%" }} variant="standard">
                <TextField
                  // label="Supplier"
                  variant="outlined"
                  size="small"
                  id="toDate"
                  type="date"
                  name="end_date"
                  onChange={(e) => handleFormdata(e.target)}
                />
                {formValidation?.end_date?.status && <WarningMessage type="error" text={formValidation?.end_date?.message} />}
              </FormControl>
              {/* </Grid>
            <Grid item xs={1}> */}
              <FormControl sx={{ m: 1 }} variant="standard">
                <Stack direction="row" spacing={2}>
                  <IconButton aria-label="delete" variant="contained" type="submit" size="small" style={{ background: "#3276d2", borderRadius: "3px", color: "#fff" }} onClick={(e) => onSubmit(e)}>
                    <SearchIcon />
                  </IconButton>
                  <IconButton aria-label="delete" variant="contained" size="small" style={{ background: "red", borderRadius: "3px", color: "#fff" }} onClick={() => window.location.reload()}>
                    <RefreshIcon />
                  </IconButton>
                </Stack>
              </FormControl>
            </Grid>
          </Grid>
          <br />
          <br />

          {filteredData?.subscription && (
            <Grid container spacing={2} className="second_level">
              {uniqueList?.importers?.length > 0 && (
                <>
                  <div style={{ width: "16%" }}>
                    <FixedTags label="Importer" data={uniqueList?.importers} selectedData={(data) => handleFormdata({ name: "s_importer_name", value: data })} refresh={secondLayerRefresh} />
                  </div>
                  <div style={{ width: "16%" }}>
                    <FixedTags label="Exporter" data={uniqueList?.exporters} selectedData={(data) => handleFormdata({ name: "s_exporter_name", value: data })} refresh={secondLayerRefresh} />
                  </div>
                  <div style={{ width: "16%" }}>
                    <FixedTags label="Country of Origin" data={uniqueList?.countries} selectedData={(data) => handleFormdata({ name: "s_country", value: data })} refresh={secondLayerRefresh} />
                  </div>
                  <div style={{ width: "16%" }}>
                    <FixedTags label="Country of Discharge" data={uniqueList?.Country_of_Destination} selectedData={(data) => handleFormdata({ name: "country_of_destination", value: data })} refresh={secondLayerRefresh} />
                  </div>
                  <div style={{ width: "16%" }}>
                    <FixedTags label="Port of Loading" data={uniqueList?.Port_of_Loading} selectedData={(data) => handleFormdata({ name: "port_of_loading", value: data })} refresh={secondLayerRefresh} />
                  </div>
                  <div style={{ width: "16%" }}>
                    <FixedTags label="Port of Discharge" data={uniqueList?.Port_of_Discharge} selectedData={(data) => handleFormdata({ name: "port_of_discharge", value: data })} refresh={secondLayerRefresh} />
                  </div>

                  <div style={{ width: "4%" }}>
                    <FormControl sx={{ m: 1 }} variant="standard">
                      <IconButton aria-label="delete" variant="contained" size="small" style={{ background: "#1976d2", borderRadius: "3px", color: "#fff", height: "36px" }} onClick={() => refreshSecondLayer()}>
                        <RefreshIcon />
                      </IconButton>
                    </FormControl>
                  </div>
                </>
              )}
            </Grid>
          )}
        </form>
        <br />
        <br />
        <br />
        {/* <hr /> */}

        <div className="import_export_filter__tableCard__data">
          {filteredData?.searchResult && shortAnalysis?.data?.Shipments && (
            <>
              <DashboardReportBox data={shortAnalysis?.data} />
              <br />
              <br />
              <br />
              {filteredData?.subscription && (
                <Stack direction="row" spacing={2} sx={{ justifyContent: "flex-end", alignItems: "center" }}>
                  <Button startIcon={<SignalCellularAltIcon />} onClick={() => handleCountryWiseAnalysis()} variant="outlined" color="primary" size="small" style={{ fontWeight: "600" }}>
                    Shipment Wise Analysis
                  </Button>
                  <Button startIcon={<DownloadForOfflineIcon />} onClick={() => handleDownloadFilterData()} variant="outlined" color="primary" size="small" style={{ fontWeight: "600" }}>
                    Download
                  </Button>
                </Stack>
              )}
              <br />
            </>
          )}

          {filteredData?.searchResult && <CustomizedTables tableData={filteredData?.searchResult} isLockHide={filteredData?.subscription} isSubscription={false} filterType={formData?.dataType} fullTableLink="trade-analysis-table?country=usa" />}
          <div className="import_export_filter__tableCard__data__pagination">
            {shortAnalysis?.data?.Shipments && (
              <Pagination count={GetPagination(shortAnalysis?.data?.Shipments)} onChange={(event, value) => (filteredData?.subscription ? setPage(value) : subscriptionModal())} showFirstButton showLastButton color="primary" />
            )}
          </div>
        </div>
      </Container>
      {!filteredData?.searchResult && <div style={{ height: "80vh" }}></div>}

      <Footer />
    </div>
  );
};
export default USATradeAnalysis;
